import React, { FC, useState, useEffect } from "react";

import { Liquid } from "liquidjs";
import shuffle from "~/lib/shuffle";

const liquidEngine = new Liquid({ strictFilters: true, strictVariables: true });

let savedVariables: { [key: string]: string } = {};
liquidEngine.registerFilter("save", (value: string, key: string) => {
  savedVariables[key] = value;
  return value;
});
liquidEngine.registerFilter("shuffle", (a, seed = 1) => shuffle(a, seed));

interface LiquidRenderProps {
  template: string;
  data: {
    [key in string]: {
      [key: string]: string;
    };
  };
}

export const resetLiquidVariables = () => {
  savedVariables = {};
}

const LiquidRender: FC<LiquidRenderProps> = ({ template, data }) => {
  const [result, setResult] = useState("");

  Object.assign(data, {saved: savedVariables});

  useEffect(() => {
    liquidEngine
      .parseAndRender(template, { data })
      .then(setResult)
      .catch((e) => {
        setResult(e.toString());
      });
  });

  return <>{result || "..."}</>;
};

export default LiquidRender;
