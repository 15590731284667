import React, { FC, useRef } from "react";
import { Apps, Shift } from "grommet-icons";
import { Text, Box, TextInput, Select } from "grommet";
import updateStep from "~/lib/requests/updateStep";

import styled from "styled-components";
import { KeyedMutator } from "swr";

const Label = styled(Box)`
  align-items: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  justify-content: space-between;

  svg {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
`;

const pickOptions = [
  { value: true, label: "Yes", selectValue: "true" },
  { value: false, label: "No", selectValue: "false" },
];

interface ChoicesStepDetailsProps {
  mutate: KeyedMutator<FlowIndexResponse>;
  flow: FlowIndexResponse;
  section: FlowSection;
  step: ChoicesStep;

  viewMode: Roles;
}

const ChoicesStepDetails: FC<ChoicesStepDetailsProps> = ({ flow, section, step, mutate, viewMode }) => {
  const choicesColumnsInput = useRef<HTMLInputElement>(null);
  const choiceListWidthInput = useRef<HTMLInputElement>(null);

  const setChoicesColumns = (count: string) => {
    if (flow && section && step) {
      const newContent: ChoicesStepContent = Object.assign({}, step.content[viewMode]);
      newContent.columns = parseInt(count);
      updateStep(mutate, flow.id, section.id, step.id, { content: { ...step.content, [viewMode]: newContent } });
    }
  };

  const setChoiceListWidth = (number: string) => {
    if (flow && section && step) {
      const newContent: ChoicesStepContent = Object.assign({}, step.content[viewMode]);
      newContent.choiceListWidth = parseInt(number);
      updateStep(mutate, flow.id, section.id, step.id, { content: { ...step.content, [viewMode]: newContent } });
    }
  };

  const setButtonHeight = (number: string) => {
    if (flow && section && step) {
      const newContent: ChoicesStepContent = Object.assign({}, step.content[viewMode]);
      newContent.buttonHeight = parseInt(number);
      updateStep(mutate, flow.id, section.id, step.id, { content: { ...step.content, [viewMode]: newContent } });
    }
  };

  return (
    <>
      <h3>Choice settings</h3>
      <Text size="small" margin={{ bottom: "xsmall" }}>
        <Label>
          <div>
            <Apps />
            Choice Columns
          </div>
        </Label>
      </Text>

      <Box direction="row" align="center">
        <TextInput
          suggestions={["1", "2", "3"]}
          onSuggestionSelect={(e) => {
            setChoicesColumns(e.suggestion);
            choicesColumnsInput.current?.blur();
          }}
          type="number"
          style={{ maxWidth: "100px", width: "100px" }}
          ref={choicesColumnsInput}
          value={step.content[viewMode].columns || 3}
          onChange={(e) => {
            if (e.target.value) {
              setChoicesColumns(e.target.value);
            }
          }}
        />
      </Box>

      <br />

      <Text size="small" margin={{ bottom: "xsmall" }}>
        <Label>
          <div>
            <Shift />
            Choice List Width
          </div>
        </Label>
      </Text>

      <Box direction="row" align="center">
        <TextInput
          suggestions={["50%", "60%", "70%", "80%", "90%"]}
          onSuggestionSelect={(e) => {
            setChoiceListWidth(e.suggestion);
            choiceListWidthInput.current?.blur();
          }}
          type="number"
          style={{ maxWidth: "100px", width: "100px" }}
          ref={choiceListWidthInput}
          value={step.content[viewMode].choiceListWidth || 90}
          onChange={(e) => {
            if (e.target.value) {
              setChoiceListWidth(e.target.value);
            }
          }}
        />
      </Box>

      <br />

      <Text size="small" margin={{ bottom: "xsmall" }}>
        <Label>
          <div>
            <Shift />
            Button Height
          </div>
        </Label>
      </Text>

      <Box direction="row" align="center">
        <TextInput
          suggestions={["auto", "100", "200"]}
          onSuggestionSelect={(e) => {
            setButtonHeight(e.suggestion);
            choiceListWidthInput.current?.blur();
          }}
          style={{ maxWidth: "100px", width: "100px" }}
          ref={choiceListWidthInput}
          value={step.content[viewMode].buttonHeight || "auto"}
          onChange={(e) => {
            if (e.target.value) {
              setButtonHeight(e.target.value);
            }
          }}
        />
      </Box>

      <br />

      <Text size="small" margin={{ bottom: "xsmall" }}>
        <Label>Enforce unique choices</Label>
      </Text>
      <Select
        options={pickOptions}
        value={step.uniquePicks ? "true" : "false"}
        labelKey="label"
        valueKey={{ key: "selectValue", reduce: true }}
        size="small"
        onChange={({ option }) => {
          updateStep(mutate, flow.id, section.id, step.id, { uniquePicks: option.value });
        }}
        a11yTitle="A drop down to select whether or not users can pick the same choice as someone else"
      />

      {step.uniquePicks ? "Participants must pick different choices" : "Participants can pick the same choice"}
    </>
  );
};

export default ChoicesStepDetails;
