import React, { FC, useState, useEffect } from "react";
import { Layer, Card, CardHeader, CardBody, CardFooter, Text } from "grommet";
import { Button } from "~/components/UI";
import styled from "styled-components";
import { KeyedMutator } from "swr";
import updateFlow from "~/lib/requests/updateFlow";
import DeleteCustomData from "./DeleteCustomData";
import { OutlineCustomData } from "../../FlowBuilder/Outline/OutlineCustomData";

interface Props {
  visible: boolean;
  setVisible: (visible: false) => void;
  flow: FlowIndexResponse;
  mutate: KeyedMutator<FlowIndexResponse>;
  locale: string;
  languages: Language[] | undefined;
}

const Form = styled.form`
  font-size: 14px;
  width: 100%;
`;

const CustomContent: FC<Props> = ({ visible, setVisible, mutate, flow, locale, languages }) => {
  const [data, setData] = useState(flow.data);
  const keys = Object.keys(data[locale] || data["en"] || {});
  keys.sort();
  const [selectedKey, setSelectedKey] = useState(keys[0]);
  const [showDeleteCustomDataModal, setShowDeleteCustomDataModal] = useState<false | { key: string }>(false);

  const avaliableLocales = languages?.map((l) => {
    return l.code;
  });

  useEffect(() => {
    if (data[locale]) {
      if (!Object.keys(data[locale]).includes(selectedKey)) {
        setSelectedKey(keys[0]);
      }
    }
  }, [data]);

  const saveFlow = () => {
    updateFlow(flow, { data }, mutate);
  };

  if (!visible) return null;
  return (
    <Layer onEsc={() => setVisible(false)} onClickOutside={() => setVisible(false)} animation="fadeIn">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          saveFlow();
        }}
      >
        <Card>
          <CardHeader pad="small">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text size="small" style={{ fontWeight: 600 }}>
                Custom Data
              </Text>

              <Text size="small">
                Create custom data that is accessible in prompts using the <code>{"{{ data.YOUR_KEY_HERE }}"}</code>{" "}
                tag. <br />
                <a href="#" onClick={() => alert("Coming soon")}>
                  Learn more about tags
                </a>
              </Text>
            </div>
          </CardHeader>
          <CardBody pad={{ horizontal: "small", bottom: "small" }}>
            <OutlineCustomData
              data={data}
              setData={setData}
              languages={languages}
              keys={keys}
              setShowDeleteCustomDataModal={setShowDeleteCustomDataModal}
              flow={flow}
              availableLocales={avaliableLocales}
            />
            {/* )} */}
          </CardBody>
          <CardFooter pad="small" background="light-2">
            <Button secondary size="small" onClick={() => setVisible(false)} label="Cancel" />

            <Button
              primary
              size="small"
              label="Save"
              type="submit"
              onClick={() => {
                saveFlow();
                setVisible(false);
              }}
            />
          </CardFooter>
        </Card>
      </Form>

      {showDeleteCustomDataModal && (
        <DeleteCustomData
          keyToDelete={showDeleteCustomDataModal.key}
          visible={!!showDeleteCustomDataModal}
          setVisible={setShowDeleteCustomDataModal}
          flow={flow}
          setData={setData}
          availableLocales={avaliableLocales}
        />
      )}
    </Layer>
  );
};

export default CustomContent;
