import React, { FC, useRef } from "react";
import { Text, Box, TextInput } from "grommet";
import { Apps } from "grommet-icons";
import styled from "styled-components";
import { KeyedMutator } from "swr";
import updateStep from "~/lib/requests/updateStep";

const Label = styled(Box)`
  align-items: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  svg {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
`;

interface FeedbackScoreDetailsProps {
  mutate: KeyedMutator<FlowIndexResponse>;
  flow: FlowIndexResponse;
  section: FlowSection;
  step: FeedbackScoreStep;
  viewMode: Roles;
}

const FeedbackScoreDetails: FC<FeedbackScoreDetailsProps> = ({ mutate, section, step, viewMode, flow }) => {
  const maxValueInput = useRef<HTMLInputElement>(null);
  const showInfinityButtonInput = useRef<HTMLInputElement>(null);

  const setShowInfinityButton = (enabled: boolean) => {
    if (flow && section && step) {
      const newSettings = Object.assign({}, step.settings);
      newSettings.showInfinityButton = enabled;
      //@ts-ignore
      updateStep(mutate, flow.id, section.id, step.id, { settings: newSettings });
    }
  };

  const setMaxValue = (maxValue: number) => {
    if (flow && section && step) {
      const newContent: FeedbackScoreStepContent = Object.assign({}, step.content[viewMode]);
      newContent.maxValue = maxValue;
      //@ts-ignore
      updateStep(mutate, flow.id, section.id, step.id, { content: { [viewMode]: newContent } });
    }
  };

  return (
    <>
      <h3>Feedback Score settings</h3>

      <Text size="small" margin={{ bottom: "xsmall" }}>
        <Label style={{marginBottom:"25px"}}>
          <div>
            <input
              type="checkbox" ref={showInfinityButtonInput}
              checked={step.settings.showInfinityButton}
              onChange={(e) => {
                if (e.target.value) {
                  setShowInfinityButton(e.target.checked);
                  updateStep(mutate, flow.id, section.id, step.id, {
                    // @ts-ignore
                    content: {
                      [viewMode]: {
                        ...step.content[viewMode],
                        showInfinityButton: e.target.checked,
                      },
                    },
                  });
              }
            }}/>
            &nbsp;Show infinity button <strong>∞</strong>
          </div>
        </Label>
      </Text>
      <Text size="small" margin={{ bottom: "xsmall" }}>
        <Label>
          <div>
            <Apps />
            Score Max Value
          </div>
        </Label>
      </Text>
      <Box direction="row" align="center" justify="start" margin={{ bottom: "small" }}>
        <Box margin={{ right: "small" }}>
          <TextInput
            suggestions={["3", "5", "10"]}
            onSuggestionSelect={(e) => {
              setMaxValue(parseInt(e.suggestion));
              maxValueInput.current?.blur();
            }}
            ref={maxValueInput}
            type="number"
            style={{ maxWidth: "100px", width: "100px" }}
            value={step.content[viewMode].maxValue || 10}
            onChange={(e) => {
              if (e.target.value) {
                setMaxValue(parseInt(e.target.value));
                updateStep(mutate, flow.id, section.id, step.id, {
                  // @ts-ignore
                  content: {
                    [viewMode]: {
                      ...step.content[viewMode],
                      maxValue: parseInt(e.target.value),
                    },
                  },
                });
              }
            }}
          />
        </Box>
      </Box>
      Defines the maximum value of the score
    </>
  );
};

export default FeedbackScoreDetails;
