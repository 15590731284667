import React from "react";
import { toast } from "react-toastify";
import { KeyedMutator } from "swr";

export default async (
  mutate: KeyedMutator<FlowIndexResponse>,
  flow: FlowIndexResponse,
  sectionID: number,
  body: FlowSectionV1
) => {
  return fetch(`${process.env.FLOW_BUILDER_API_URL}/flows/${flow.id}/sections/${sectionID}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (response.status !== 200) {
        throw "error";
      }
      return response.json();
    })
    .then(() => {
      mutate();
    })
    .catch(() => {
      toast(
        <>
          <b>Error re-ordering steps</b>
          <br />
          It was not possible update this section due to an error. Please try again later or contact support.
        </>
      );
    });
};
