import React, { FC } from "react";
import { Box, Select } from "grommet";
import styled from "styled-components";
import { KeyedMutator } from "swr";
import updateStep from "~/lib/requests/updateStep";

const Label = styled(Box)`
  align-items: center;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  svg {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }
`;

interface Props {
  mutate: KeyedMutator<FlowIndexResponse>;
  flow: FlowIndexResponse;
  section: FlowSection;
  step: AnySettingsStep;

  viewMode: Roles;
}

const presenceStepOptions = [
  {value: "video", label: "Video only", selectValue: "video" },
  {value: "in-person", label: "In-person only", selectValue: "in-person" },
  {value: "all", label: "Always run", selectValue: "all" },
]

const sequenceStepOptions = [
  {value: "sequenced", label: "Sequenced flows only", selectValue: "sequenced" },
  {value: "single", label: "Single flow only", selectValue: "single" },
  {value: "all", label: "Always run", selectValue: "all" },
  {value: "first", label: "First only", selectValue: "first" },
  {value: "last", label: "Last only", selectValue: "last" },
  {value: "notFirst", label: "Not First", selectValue: "notFirst" },
  {value: "notLast", label: "Not Last", selectValue: "notLast" },
]

const AllStepDetails: FC<Props> = ({ flow, section, step, mutate }) => {

  let sequenceVisibilityDescription = "";
    switch(step.settings.sequenceVisibility) {
      case "sequenced": {
        sequenceVisibilityDescription = "This step will only run if this flow is part of a sequence of flows";
        break;
      }
      case "first": {
        sequenceVisibilityDescription = "This step will only run if this is the FIRST flow of a sequence of flows, including single flows.";
        break;
      }
      case "last": {
        sequenceVisibilityDescription = "This step will only run if this is the LAST flow of a sequence of flows, including single flows.";
        break;
      }
      case "notFirst": {
        sequenceVisibilityDescription = "This step will only run on sequenced flows EXCEPT if this is the FIRST flow. It will never run on single flows.";
        break;
      }
      case "notLast": {
        sequenceVisibilityDescription = "This step will only run on sequenced flows run EXCEPT if this is the LAST flow. It will never run on single flows.";
        break;
      }
      case "single": {
        sequenceVisibilityDescription = "This step will only run if it's run alone and NOT part of a sequence";
        break;
      }
      case "all": {
        sequenceVisibilityDescription = "This step will always run for both single flows and sequenced flows";
        break;
      }
    }

  // step.settings.sequenceVisibility == "sequenced" ? "This step will only run if this flow is part of a sequence of flows" :
  //   step.settings.sequenceVisibility == "single" ? "This step will only run if it's run alone and NOT part of a sequence" :
  //     "This step will always run for both single flows and sequenced flows"


  return (
    <>
      <Label>Presence Visibility:</Label>
      <Select
        options={presenceStepOptions}
        value={step.settings.visibility?.toString() || "all"}
        labelKey="label"
        valueKey={{ key: "selectValue", reduce: true }}
        size="small"
        onChange={({ option }) => {
          //@ts-ignore
          updateStep(mutate, flow.id, section.id, step.id, { settings: { visibility: option.value } });
        }}
        a11yTitle="A drop down to select when this flow should run: in-person, video, or always"
      />
      <br />
      {
        step.settings.visibility == "video" ? "This step will only run during video sessions" :
        step.settings.visibility == "in-person" ? "This step will only run during in-person sessions" :
          "This step will always run for both video and in-person sessions"
      }
      <br />
      <br />


      <Label>Sequence Visibility:</Label>
      <Select
        options={sequenceStepOptions}
        value={step.settings.sequenceVisibility?.toString() || "all"}
        labelKey="label"
        valueKey={{ key: "selectValue", reduce: true }}
        size="small"
        onChange={({ option }) => {
          //@ts-ignore
          updateStep(mutate, flow.id, section.id, step.id, { settings: { sequenceVisibility: option.value } });
        }}
        a11yTitle="A drop down to select when this flow should run: in-person, video, or always"
      />
      <br />
      { sequenceVisibilityDescription }
      <br />
      <br />
    </>
  );
};

export default AllStepDetails;
